// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts,import_extension=none"
// @generated from file svc/frontend/portal/proto/api/api.proto (package api, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateBulkOpportunitiesRequest, CreateBulkOpportunitiesResponse, CreateOpportunitiesRequest, CreateOpportunitiesResponse, GetCommunicationParticipantNamesRequest, GetCommunicationParticipantNamesResponse, GetSessionRequest, GetSessionResponse, ValidateCommunicationRequest, ValidateCommunicationResponse } from "./api_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * PortalFrontendAPI implements Portal Frontend API specific RPCs.
 *
 * @generated from service api.PortalFrontendAPI
 */
export const PortalFrontendAPI = {
  typeName: "api.PortalFrontendAPI",
  methods: {
    /**
     * GetSession returns extra information about the current authenticated agent.
     *
     * @generated from rpc api.PortalFrontendAPI.GetSession
     */
    getSession: {
      name: "GetSession",
      I: GetSessionRequest,
      O: GetSessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Create bulk of opportunities for a new/existing contact.
     *
     * @generated from rpc api.PortalFrontendAPI.CreateOpportunities
     */
    createOpportunities: {
      name: "CreateOpportunities",
      I: CreateOpportunitiesRequest,
      O: CreateOpportunitiesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Create bulk of opportunities for multiple contacts.
     *
     * @generated from rpc api.PortalFrontendAPI.CreateBulkOpportunities
     */
    createBulkOpportunities: {
      name: "CreateBulkOpportunities",
      I: CreateBulkOpportunitiesRequest,
      O: CreateBulkOpportunitiesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ValidateCommunication checks if given communication is ok to be sent to the
     * target list.
     *
     * @generated from rpc api.PortalFrontendAPI.ValidateCommunication
     */
    validateCommunication: {
      name: "ValidateCommunication",
      I: ValidateCommunicationRequest,
      O: ValidateCommunicationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetCommunicationParticipantNames returns From/To names for communication
     * with given contact IDs.
     *
     * @generated from rpc api.PortalFrontendAPI.GetCommunicationParticipantNames
     */
    getCommunicationParticipantNames: {
      name: "GetCommunicationParticipantNames",
      I: GetCommunicationParticipantNamesRequest,
      O: GetCommunicationParticipantNamesResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

