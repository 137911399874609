import { QualificationStatus } from '@agentero/grpc-clients/grpc/portal-fe';
import { QualificationStatus as QualificationStatusConnect } from '@agentero/service-clients/api';

const bwaQualificationMapping: Record<QualificationStatus, boolean | undefined> = {
	[QualificationStatus.QUALIFICATION_STATUS_UNSPECIFIED]: undefined,
	[QualificationStatus.QUALIFICATION_STATUS_QUALIFIED]: true,
	[QualificationStatus.QUALIFICATION_STATUS_PENDING_QUALIFICATION]: true,
	[QualificationStatus.QUALIFICATION_STATUS_NOT_QUALIFIED]: false
};

export const parseBwAQualification = (bwaQualification: QualificationStatus): boolean | undefined =>
	bwaQualificationMapping[bwaQualification];

const bwaQualificationMappingConnect: Record<QualificationStatusConnect, boolean | undefined> = {
	[QualificationStatusConnect.UNSPECIFIED]: undefined,
	[QualificationStatusConnect.QUALIFIED]: true,
	[QualificationStatusConnect.PENDING_QUALIFICATION]: true,
	[QualificationStatusConnect.NOT_QUALIFIED]: false
};

export const parseBwAQualificationConnect = (
	bwaQualification: QualificationStatusConnect
): boolean | undefined => bwaQualificationMappingConnect[bwaQualification];
