// @generated by protoc-gen-es v1.5.1 with parameter "target=ts,import_extension=none"
// @generated from file svc/frontend/portal/proto/api/api.proto (package api, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { InsuranceType } from "../../../../../pkg/proto/policy/policy_pb";
import { EAndOStatus, OperateAs } from "../agency/agency_pb";
import { LicenseGlobalStatus } from "../../../../../pkg/proto/license/license_pb";
import { CurrentTier } from "../../../../../pkg/proto/tier/tier_pb";
import { CommercialCompanyInfo, ContactLineType } from "../../../../../pkg/proto/contact/contact_pb";

/**
 * QualificationStatus represents the bwa qualification status.
 *
 * @generated from enum api.QualificationStatus
 */
export enum QualificationStatus {
  /**
   * @generated from enum value: QUALIFICATION_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: QUALIFICATION_STATUS_QUALIFIED = 1;
   */
  QUALIFIED = 1,

  /**
   * @generated from enum value: QUALIFICATION_STATUS_NOT_QUALIFIED = 2;
   */
  NOT_QUALIFIED = 2,

  /**
   * @generated from enum value: QUALIFICATION_STATUS_PENDING_QUALIFICATION = 3;
   */
  PENDING_QUALIFICATION = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(QualificationStatus)
proto3.util.setEnumType(QualificationStatus, "api.QualificationStatus", [
  { no: 0, name: "QUALIFICATION_STATUS_UNSPECIFIED" },
  { no: 1, name: "QUALIFICATION_STATUS_QUALIFIED" },
  { no: 2, name: "QUALIFICATION_STATUS_NOT_QUALIFIED" },
  { no: 3, name: "QUALIFICATION_STATUS_PENDING_QUALIFICATION" },
]);

/**
 * @generated from enum api.AgentRole
 */
export enum AgentRole {
  /**
   * @generated from enum value: AGENT_ROLE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: AGENT_ROLE_REGULAR_AGENT = 1;
   */
  REGULAR_AGENT = 1,

  /**
   * @generated from enum value: AGENT_ROLE_AGENCY_ADMIN = 2;
   */
  AGENCY_ADMIN = 2,

  /**
   * @generated from enum value: AGENT_ROLE_SUPER_ADMIN = 3;
   */
  SUPER_ADMIN = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(AgentRole)
proto3.util.setEnumType(AgentRole, "api.AgentRole", [
  { no: 0, name: "AGENT_ROLE_UNSPECIFIED" },
  { no: 1, name: "AGENT_ROLE_REGULAR_AGENT" },
  { no: 2, name: "AGENT_ROLE_AGENCY_ADMIN" },
  { no: 3, name: "AGENT_ROLE_SUPER_ADMIN" },
]);

/**
 * @generated from message api.GetSessionRequest
 */
export class GetSessionRequest extends Message<GetSessionRequest> {
  constructor(data?: PartialMessage<GetSessionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.GetSessionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSessionRequest {
    return new GetSessionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSessionRequest {
    return new GetSessionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSessionRequest {
    return new GetSessionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetSessionRequest | PlainMessage<GetSessionRequest> | undefined, b: GetSessionRequest | PlainMessage<GetSessionRequest> | undefined): boolean {
    return proto3.util.equals(GetSessionRequest, a, b);
  }
}

/**
 * @generated from message api.GetSessionResponse
 */
export class GetSessionResponse extends Message<GetSessionResponse> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string full_name = 3;
   */
  fullName = "";

  /**
   * @generated from field: string agency_id = 5;
   */
  agencyId = "";

  /**
   * @generated from field: string agency_name = 6;
   */
  agencyName = "";

  /**
   * @generated from field: bool has_requested_import_email_setup = 12;
   */
  hasRequestedImportEmailSetup = false;

  /**
   * @generated from field: bool binding_with_agentero = 15;
   */
  bindingWithAgentero = false;

  /**
   * @generated from field: bool has_commercial_rater = 16;
   */
  hasCommercialRater = false;

  /**
   * @generated from field: bool appointments_enabled = 17;
   */
  appointmentsEnabled = false;

  /**
   * @generated from field: repeated proto.policy.InsuranceType quotable_lobs = 18;
   */
  quotableLobs: InsuranceType[] = [];

  /**
   * @generated from field: portal.agency.EAndOStatus e_and_o_status = 19;
   */
  eAndOStatus = EAndOStatus.UNSPECIFIED;

  /**
   * @generated from field: api.QualificationStatus bwa_qualification = 20;
   */
  bwaQualification = QualificationStatus.UNSPECIFIED;

  /**
   * @generated from field: proto.license.LicenseGlobalStatus agency_license_status = 21;
   */
  agencyLicenseStatus = LicenseGlobalStatus.UNSPECIFIED;

  /**
   * @generated from field: proto.license.LicenseGlobalStatus agent_license_status = 22;
   */
  agentLicenseStatus = LicenseGlobalStatus.UNSPECIFIED;

  /**
   * @generated from field: portal.agency.OperateAs how_operate_as = 23;
   */
  howOperateAs = OperateAs.UNSPECIFIED;

  /**
   * @generated from field: proto.license.LicenseGlobalStatus agency_resident_license_status = 24;
   */
  agencyResidentLicenseStatus = LicenseGlobalStatus.UNSPECIFIED;

  /**
   * @generated from field: proto.license.LicenseGlobalStatus agent_resident_license_status = 25;
   */
  agentResidentLicenseStatus = LicenseGlobalStatus.UNSPECIFIED;

  /**
   * @generated from field: proto.license.LicenseGlobalStatus agency_admin_license_status = 26;
   */
  agencyAdminLicenseStatus = LicenseGlobalStatus.UNSPECIFIED;

  /**
   * @generated from field: proto.tier.CurrentTier current_tier = 27;
   */
  currentTier?: CurrentTier;

  /**
   * @generated from field: api.CommunicationPreferences communication_preferences = 28;
   */
  communicationPreferences?: CommunicationPreferences;

  /**
   * @generated from field: bool has_terminated_appointments = 29;
   */
  hasTerminatedAppointments = false;

  /**
   * The following fields must be included to replace the current_expert endpoint
   *
   * @generated from field: string token = 30;
   */
  token = "";

  /**
   * @generated from field: string first_name = 31;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 32;
   */
  lastName = "";

  /**
   * @generated from field: string photo_url = 33;
   */
  photoUrl = "";

  /**
   * @generated from field: bool is_impersonated = 34;
   */
  isImpersonated = false;

  /**
   * @generated from field: bool is_contract_accepted = 35;
   */
  isContractAccepted = false;

  /**
   * @generated from field: string phone_number = 36;
   */
  phoneNumber = "";

  /**
   * @generated from field: string agency_form_stack_name = 37;
   */
  agencyFormStackName = "";

  /**
   * Before called "is_agency_admin".
   *
   * @generated from field: api.AgentRole role = 38;
   */
  role = AgentRole.UNSPECIFIED;

  constructor(data?: PartialMessage<GetSessionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.GetSessionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "agency_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "agency_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "has_requested_import_email_setup", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "binding_with_agentero", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "has_commercial_rater", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "appointments_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "quotable_lobs", kind: "enum", T: proto3.getEnumType(InsuranceType), repeated: true },
    { no: 19, name: "e_and_o_status", kind: "enum", T: proto3.getEnumType(EAndOStatus) },
    { no: 20, name: "bwa_qualification", kind: "enum", T: proto3.getEnumType(QualificationStatus) },
    { no: 21, name: "agency_license_status", kind: "enum", T: proto3.getEnumType(LicenseGlobalStatus) },
    { no: 22, name: "agent_license_status", kind: "enum", T: proto3.getEnumType(LicenseGlobalStatus) },
    { no: 23, name: "how_operate_as", kind: "enum", T: proto3.getEnumType(OperateAs) },
    { no: 24, name: "agency_resident_license_status", kind: "enum", T: proto3.getEnumType(LicenseGlobalStatus) },
    { no: 25, name: "agent_resident_license_status", kind: "enum", T: proto3.getEnumType(LicenseGlobalStatus) },
    { no: 26, name: "agency_admin_license_status", kind: "enum", T: proto3.getEnumType(LicenseGlobalStatus) },
    { no: 27, name: "current_tier", kind: "message", T: CurrentTier },
    { no: 28, name: "communication_preferences", kind: "message", T: CommunicationPreferences },
    { no: 29, name: "has_terminated_appointments", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 30, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 32, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 33, name: "photo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 34, name: "is_impersonated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 35, name: "is_contract_accepted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 36, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 37, name: "agency_form_stack_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 38, name: "role", kind: "enum", T: proto3.getEnumType(AgentRole) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSessionResponse {
    return new GetSessionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSessionResponse {
    return new GetSessionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSessionResponse {
    return new GetSessionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetSessionResponse | PlainMessage<GetSessionResponse> | undefined, b: GetSessionResponse | PlainMessage<GetSessionResponse> | undefined): boolean {
    return proto3.util.equals(GetSessionResponse, a, b);
  }
}

/**
 * @generated from message api.CommunicationPreferences
 */
export class CommunicationPreferences extends Message<CommunicationPreferences> {
  /**
   * @generated from field: bool email = 1;
   */
  email = false;

  /**
   * @generated from field: bool sms = 2;
   */
  sms = false;

  constructor(data?: PartialMessage<CommunicationPreferences>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.CommunicationPreferences";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "sms", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CommunicationPreferences {
    return new CommunicationPreferences().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CommunicationPreferences {
    return new CommunicationPreferences().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CommunicationPreferences {
    return new CommunicationPreferences().fromJsonString(jsonString, options);
  }

  static equals(a: CommunicationPreferences | PlainMessage<CommunicationPreferences> | undefined, b: CommunicationPreferences | PlainMessage<CommunicationPreferences> | undefined): boolean {
    return proto3.util.equals(CommunicationPreferences, a, b);
  }
}

/**
 * @generated from message api.CreateOpportunitiesRequest
 */
export class CreateOpportunitiesRequest extends Message<CreateOpportunitiesRequest> {
  /**
   * @generated from field: api.Opportunities opportunities = 1;
   */
  opportunities?: Opportunities;

  /**
   * @generated from field: api.Contact contact = 2;
   */
  contact?: Contact;

  /**
   * @generated from field: bool quote = 3;
   */
  quote = false;

  constructor(data?: PartialMessage<CreateOpportunitiesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.CreateOpportunitiesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "opportunities", kind: "message", T: Opportunities },
    { no: 2, name: "contact", kind: "message", T: Contact },
    { no: 3, name: "quote", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOpportunitiesRequest {
    return new CreateOpportunitiesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOpportunitiesRequest {
    return new CreateOpportunitiesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOpportunitiesRequest {
    return new CreateOpportunitiesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOpportunitiesRequest | PlainMessage<CreateOpportunitiesRequest> | undefined, b: CreateOpportunitiesRequest | PlainMessage<CreateOpportunitiesRequest> | undefined): boolean {
    return proto3.util.equals(CreateOpportunitiesRequest, a, b);
  }
}

/**
 * @generated from message api.CreateOpportunitiesResponse
 */
export class CreateOpportunitiesResponse extends Message<CreateOpportunitiesResponse> {
  /**
   * @generated from field: repeated string opportunities = 1;
   */
  opportunities: string[] = [];

  /**
   * @generated from field: int32 success_quote_count = 2;
   */
  successQuoteCount = 0;

  /**
   * @generated from field: int32 failure_quote_count = 3;
   */
  failureQuoteCount = 0;

  constructor(data?: PartialMessage<CreateOpportunitiesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.CreateOpportunitiesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "opportunities", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "success_quote_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "failure_quote_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateOpportunitiesResponse {
    return new CreateOpportunitiesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateOpportunitiesResponse {
    return new CreateOpportunitiesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateOpportunitiesResponse {
    return new CreateOpportunitiesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateOpportunitiesResponse | PlainMessage<CreateOpportunitiesResponse> | undefined, b: CreateOpportunitiesResponse | PlainMessage<CreateOpportunitiesResponse> | undefined): boolean {
    return proto3.util.equals(CreateOpportunitiesResponse, a, b);
  }
}

/**
 * @generated from message api.ValidateCommunicationRequest
 */
export class ValidateCommunicationRequest extends Message<ValidateCommunicationRequest> {
  /**
   * @generated from oneof api.ValidateCommunicationRequest.target
   */
  target: {
    /**
     * @generated from field: api.ValidateCommunicationRequest.ContactBulk contact_bulk = 1;
     */
    value: ValidateCommunicationRequest_ContactBulk;
    case: "contactBulk";
  } | {
    /**
     * @generated from field: api.ValidateCommunicationRequest.OpportunityBulk opportunity_bulk = 2;
     */
    value: ValidateCommunicationRequest_OpportunityBulk;
    case: "opportunityBulk";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: string body = 3;
   */
  body = "";

  constructor(data?: PartialMessage<ValidateCommunicationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.ValidateCommunicationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contact_bulk", kind: "message", T: ValidateCommunicationRequest_ContactBulk, oneof: "target" },
    { no: 2, name: "opportunity_bulk", kind: "message", T: ValidateCommunicationRequest_OpportunityBulk, oneof: "target" },
    { no: 3, name: "body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateCommunicationRequest {
    return new ValidateCommunicationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateCommunicationRequest {
    return new ValidateCommunicationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateCommunicationRequest {
    return new ValidateCommunicationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateCommunicationRequest | PlainMessage<ValidateCommunicationRequest> | undefined, b: ValidateCommunicationRequest | PlainMessage<ValidateCommunicationRequest> | undefined): boolean {
    return proto3.util.equals(ValidateCommunicationRequest, a, b);
  }
}

/**
 * @generated from message api.ValidateCommunicationRequest.ContactBulk
 */
export class ValidateCommunicationRequest_ContactBulk extends Message<ValidateCommunicationRequest_ContactBulk> {
  /**
   * @generated from field: repeated string contact_ids = 1;
   */
  contactIds: string[] = [];

  constructor(data?: PartialMessage<ValidateCommunicationRequest_ContactBulk>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.ValidateCommunicationRequest.ContactBulk";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contact_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateCommunicationRequest_ContactBulk {
    return new ValidateCommunicationRequest_ContactBulk().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateCommunicationRequest_ContactBulk {
    return new ValidateCommunicationRequest_ContactBulk().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateCommunicationRequest_ContactBulk {
    return new ValidateCommunicationRequest_ContactBulk().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateCommunicationRequest_ContactBulk | PlainMessage<ValidateCommunicationRequest_ContactBulk> | undefined, b: ValidateCommunicationRequest_ContactBulk | PlainMessage<ValidateCommunicationRequest_ContactBulk> | undefined): boolean {
    return proto3.util.equals(ValidateCommunicationRequest_ContactBulk, a, b);
  }
}

/**
 * @generated from message api.ValidateCommunicationRequest.OpportunityBulk
 */
export class ValidateCommunicationRequest_OpportunityBulk extends Message<ValidateCommunicationRequest_OpportunityBulk> {
  /**
   * @generated from field: repeated string opportunity_ids = 1;
   */
  opportunityIds: string[] = [];

  constructor(data?: PartialMessage<ValidateCommunicationRequest_OpportunityBulk>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.ValidateCommunicationRequest.OpportunityBulk";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "opportunity_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateCommunicationRequest_OpportunityBulk {
    return new ValidateCommunicationRequest_OpportunityBulk().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateCommunicationRequest_OpportunityBulk {
    return new ValidateCommunicationRequest_OpportunityBulk().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateCommunicationRequest_OpportunityBulk {
    return new ValidateCommunicationRequest_OpportunityBulk().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateCommunicationRequest_OpportunityBulk | PlainMessage<ValidateCommunicationRequest_OpportunityBulk> | undefined, b: ValidateCommunicationRequest_OpportunityBulk | PlainMessage<ValidateCommunicationRequest_OpportunityBulk> | undefined): boolean {
    return proto3.util.equals(ValidateCommunicationRequest_OpportunityBulk, a, b);
  }
}

/**
 * @generated from message api.ValidateCommunicationResponse
 */
export class ValidateCommunicationResponse extends Message<ValidateCommunicationResponse> {
  /**
   * @generated from field: repeated api.ValidateCommunicationResponse.Error errors = 1;
   */
  errors: ValidateCommunicationResponse_Error[] = [];

  constructor(data?: PartialMessage<ValidateCommunicationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.ValidateCommunicationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "errors", kind: "message", T: ValidateCommunicationResponse_Error, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateCommunicationResponse {
    return new ValidateCommunicationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateCommunicationResponse {
    return new ValidateCommunicationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateCommunicationResponse {
    return new ValidateCommunicationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateCommunicationResponse | PlainMessage<ValidateCommunicationResponse> | undefined, b: ValidateCommunicationResponse | PlainMessage<ValidateCommunicationResponse> | undefined): boolean {
    return proto3.util.equals(ValidateCommunicationResponse, a, b);
  }
}

/**
 * @generated from message api.ValidateCommunicationResponse.Error
 */
export class ValidateCommunicationResponse_Error extends Message<ValidateCommunicationResponse_Error> {
  /**
   * @generated from oneof api.ValidateCommunicationResponse.Error.type
   */
  type: {
    /**
     * @generated from field: api.ValidateCommunicationResponse.Error.UnavailableKeyword unavailable_keyword = 1;
     */
    value: ValidateCommunicationResponse_Error_UnavailableKeyword;
    case: "unavailableKeyword";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ValidateCommunicationResponse_Error>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.ValidateCommunicationResponse.Error";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unavailable_keyword", kind: "message", T: ValidateCommunicationResponse_Error_UnavailableKeyword, oneof: "type" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateCommunicationResponse_Error {
    return new ValidateCommunicationResponse_Error().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateCommunicationResponse_Error {
    return new ValidateCommunicationResponse_Error().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateCommunicationResponse_Error {
    return new ValidateCommunicationResponse_Error().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateCommunicationResponse_Error | PlainMessage<ValidateCommunicationResponse_Error> | undefined, b: ValidateCommunicationResponse_Error | PlainMessage<ValidateCommunicationResponse_Error> | undefined): boolean {
    return proto3.util.equals(ValidateCommunicationResponse_Error, a, b);
  }
}

/**
 * @generated from enum api.ValidateCommunicationResponse.Error.Keyword
 */
export enum ValidateCommunicationResponse_Error_Keyword {
  /**
   * @generated from enum value: HAVEN_LIFE_REFERRAL_LINK = 0;
   */
  HAVEN_LIFE_REFERRAL_LINK = 0,

  /**
   * @generated from enum value: GET_COVERED_RENTERS_URL = 1;
   */
  GET_COVERED_RENTERS_URL = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(ValidateCommunicationResponse_Error_Keyword)
proto3.util.setEnumType(ValidateCommunicationResponse_Error_Keyword, "api.ValidateCommunicationResponse.Error.Keyword", [
  { no: 0, name: "HAVEN_LIFE_REFERRAL_LINK" },
  { no: 1, name: "GET_COVERED_RENTERS_URL" },
]);

/**
 * @generated from message api.ValidateCommunicationResponse.Error.UnavailableKeyword
 */
export class ValidateCommunicationResponse_Error_UnavailableKeyword extends Message<ValidateCommunicationResponse_Error_UnavailableKeyword> {
  /**
   * @generated from field: api.ValidateCommunicationResponse.Error.Keyword keyword = 1;
   */
  keyword = ValidateCommunicationResponse_Error_Keyword.HAVEN_LIFE_REFERRAL_LINK;

  /**
   * @generated from field: string reason = 2;
   */
  reason = "";

  constructor(data?: PartialMessage<ValidateCommunicationResponse_Error_UnavailableKeyword>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.ValidateCommunicationResponse.Error.UnavailableKeyword";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword", kind: "enum", T: proto3.getEnumType(ValidateCommunicationResponse_Error_Keyword) },
    { no: 2, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateCommunicationResponse_Error_UnavailableKeyword {
    return new ValidateCommunicationResponse_Error_UnavailableKeyword().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateCommunicationResponse_Error_UnavailableKeyword {
    return new ValidateCommunicationResponse_Error_UnavailableKeyword().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ValidateCommunicationResponse_Error_UnavailableKeyword {
    return new ValidateCommunicationResponse_Error_UnavailableKeyword().fromJsonString(jsonString, options);
  }

  static equals(a: ValidateCommunicationResponse_Error_UnavailableKeyword | PlainMessage<ValidateCommunicationResponse_Error_UnavailableKeyword> | undefined, b: ValidateCommunicationResponse_Error_UnavailableKeyword | PlainMessage<ValidateCommunicationResponse_Error_UnavailableKeyword> | undefined): boolean {
    return proto3.util.equals(ValidateCommunicationResponse_Error_UnavailableKeyword, a, b);
  }
}

/**
 * @generated from message api.GetCommunicationParticipantNamesRequest
 */
export class GetCommunicationParticipantNamesRequest extends Message<GetCommunicationParticipantNamesRequest> {
  /**
   * @generated from field: repeated string contact_ids = 1;
   */
  contactIds: string[] = [];

  /**
   * limit is the maximum number of names to return.
   *
   * @generated from field: int32 limit = 2;
   */
  limit = 0;

  constructor(data?: PartialMessage<GetCommunicationParticipantNamesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.GetCommunicationParticipantNamesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contact_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCommunicationParticipantNamesRequest {
    return new GetCommunicationParticipantNamesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCommunicationParticipantNamesRequest {
    return new GetCommunicationParticipantNamesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCommunicationParticipantNamesRequest {
    return new GetCommunicationParticipantNamesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCommunicationParticipantNamesRequest | PlainMessage<GetCommunicationParticipantNamesRequest> | undefined, b: GetCommunicationParticipantNamesRequest | PlainMessage<GetCommunicationParticipantNamesRequest> | undefined): boolean {
    return proto3.util.equals(GetCommunicationParticipantNamesRequest, a, b);
  }
}

/**
 * @generated from message api.GetCommunicationParticipantNamesResponse
 */
export class GetCommunicationParticipantNamesResponse extends Message<GetCommunicationParticipantNamesResponse> {
  /**
   * @generated from field: repeated string from = 1;
   */
  from: string[] = [];

  /**
   * @generated from field: repeated string to = 2;
   */
  to: string[] = [];

  constructor(data?: PartialMessage<GetCommunicationParticipantNamesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.GetCommunicationParticipantNamesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "from", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "to", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCommunicationParticipantNamesResponse {
    return new GetCommunicationParticipantNamesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCommunicationParticipantNamesResponse {
    return new GetCommunicationParticipantNamesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCommunicationParticipantNamesResponse {
    return new GetCommunicationParticipantNamesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCommunicationParticipantNamesResponse | PlainMessage<GetCommunicationParticipantNamesResponse> | undefined, b: GetCommunicationParticipantNamesResponse | PlainMessage<GetCommunicationParticipantNamesResponse> | undefined): boolean {
    return proto3.util.equals(GetCommunicationParticipantNamesResponse, a, b);
  }
}

/**
 * @generated from message api.Contact
 */
export class Contact extends Message<Contact> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string first_name = 2;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 3;
   */
  lastName = "";

  /**
   * @generated from field: string phone_number = 4;
   */
  phoneNumber = "";

  /**
   * @generated from field: string land_line_number = 5;
   */
  landLineNumber = "";

  /**
   * Contact address
   *
   * @generated from field: api.Address address = 6;
   */
  address?: Address;

  /**
   * Contact date of birth
   *
   * @generated from field: google.protobuf.Timestamp date_of_birth = 7;
   */
  dateOfBirth?: Timestamp;

  /**
   * @generated from field: proto.contact.ContactLineType line_type = 8;
   */
  lineType = ContactLineType.PERSONAL;

  /**
   * @generated from field: api.Contact.CommercialData commercial_data = 9;
   */
  commercialData?: Contact_CommercialData;

  constructor(data?: PartialMessage<Contact>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.Contact";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "land_line_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "address", kind: "message", T: Address },
    { no: 7, name: "date_of_birth", kind: "message", T: Timestamp },
    { no: 8, name: "line_type", kind: "enum", T: proto3.getEnumType(ContactLineType) },
    { no: 9, name: "commercial_data", kind: "message", T: Contact_CommercialData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Contact {
    return new Contact().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Contact {
    return new Contact().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Contact {
    return new Contact().fromJsonString(jsonString, options);
  }

  static equals(a: Contact | PlainMessage<Contact> | undefined, b: Contact | PlainMessage<Contact> | undefined): boolean {
    return proto3.util.equals(Contact, a, b);
  }
}

/**
 * @generated from message api.Contact.CommercialData
 */
export class Contact_CommercialData extends Message<Contact_CommercialData> {
  /**
   * @generated from field: proto.contact.CommercialCompanyInfo company_info = 1;
   */
  companyInfo?: CommercialCompanyInfo;

  constructor(data?: PartialMessage<Contact_CommercialData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.Contact.CommercialData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_info", kind: "message", T: CommercialCompanyInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Contact_CommercialData {
    return new Contact_CommercialData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Contact_CommercialData {
    return new Contact_CommercialData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Contact_CommercialData {
    return new Contact_CommercialData().fromJsonString(jsonString, options);
  }

  static equals(a: Contact_CommercialData | PlainMessage<Contact_CommercialData> | undefined, b: Contact_CommercialData | PlainMessage<Contact_CommercialData> | undefined): boolean {
    return proto3.util.equals(Contact_CommercialData, a, b);
  }
}

/**
 * @generated from message api.Address
 */
export class Address extends Message<Address> {
  /**
   * @generated from field: string street = 1;
   */
  street = "";

  /**
   * @generated from field: string zip_code = 2;
   */
  zipCode = "";

  /**
   * @generated from field: string city = 3;
   */
  city = "";

  /**
   * @generated from field: string state = 4;
   */
  state = "";

  constructor(data?: PartialMessage<Address>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.Address";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "street", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "zip_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Address {
    return new Address().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Address {
    return new Address().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Address {
    return new Address().fromJsonString(jsonString, options);
  }

  static equals(a: Address | PlainMessage<Address> | undefined, b: Address | PlainMessage<Address> | undefined): boolean {
    return proto3.util.equals(Address, a, b);
  }
}

/**
 * Several opportunities one per insurance type
 *
 * @generated from message api.Opportunities
 */
export class Opportunities extends Message<Opportunities> {
  /**
   * Type of insurance.
   *
   * @generated from field: repeated proto.policy.InsuranceType insurance_type = 1;
   */
  insuranceType: InsuranceType[] = [];

  /**
   * Policy holder first name.
   *
   * @generated from field: string owner_first_name = 4;
   */
  ownerFirstName = "";

  /**
   * Policy holder last name.
   *
   * @generated from field: string owner_last_name = 5;
   */
  ownerLastName = "";

  /**
   * Id of the client related to the opportunity
   *
   * @generated from field: string owner_mongo_id = 6;
   */
  ownerMongoId = "";

  /**
   * Id of the agent responsible to the opportunity
   *
   * @generated from field: string agent_id = 7;
   */
  agentId = "";

  /**
   * Note
   *
   * @generated from field: string agent_note = 8;
   */
  agentNote = "";

  /**
   * Opportunity address
   *
   * @generated from field: api.Address address = 9;
   */
  address?: Address;

  /**
   * Id of the policy of the opportunity
   *
   * @generated from field: string policy_id = 10;
   */
  policyId = "";

  constructor(data?: PartialMessage<Opportunities>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.Opportunities";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "insurance_type", kind: "enum", T: proto3.getEnumType(InsuranceType), repeated: true },
    { no: 4, name: "owner_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "owner_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "owner_mongo_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "agent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "agent_note", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "address", kind: "message", T: Address },
    { no: 10, name: "policy_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Opportunities {
    return new Opportunities().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Opportunities {
    return new Opportunities().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Opportunities {
    return new Opportunities().fromJsonString(jsonString, options);
  }

  static equals(a: Opportunities | PlainMessage<Opportunities> | undefined, b: Opportunities | PlainMessage<Opportunities> | undefined): boolean {
    return proto3.util.equals(Opportunities, a, b);
  }
}

/**
 * @generated from message api.CreateBulkOpportunitiesRequest
 */
export class CreateBulkOpportunitiesRequest extends Message<CreateBulkOpportunitiesRequest> {
  /**
   * @generated from field: repeated proto.policy.InsuranceType insurance_types = 1;
   */
  insuranceTypes: InsuranceType[] = [];

  /**
   * @generated from field: repeated string contacts = 2;
   */
  contacts: string[] = [];

  /**
   * @generated from field: string agent_note = 3;
   */
  agentNote = "";

  /**
   * @generated from field: bool quote = 4;
   */
  quote = false;

  constructor(data?: PartialMessage<CreateBulkOpportunitiesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.CreateBulkOpportunitiesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "insurance_types", kind: "enum", T: proto3.getEnumType(InsuranceType), repeated: true },
    { no: 2, name: "contacts", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "agent_note", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "quote", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateBulkOpportunitiesRequest {
    return new CreateBulkOpportunitiesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateBulkOpportunitiesRequest {
    return new CreateBulkOpportunitiesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateBulkOpportunitiesRequest {
    return new CreateBulkOpportunitiesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateBulkOpportunitiesRequest | PlainMessage<CreateBulkOpportunitiesRequest> | undefined, b: CreateBulkOpportunitiesRequest | PlainMessage<CreateBulkOpportunitiesRequest> | undefined): boolean {
    return proto3.util.equals(CreateBulkOpportunitiesRequest, a, b);
  }
}

/**
 * @generated from message api.CreateBulkOpportunitiesResponse
 */
export class CreateBulkOpportunitiesResponse extends Message<CreateBulkOpportunitiesResponse> {
  /**
   * @generated from field: repeated string opportunities = 1;
   */
  opportunities: string[] = [];

  /**
   * @generated from field: int32 quote_count = 2;
   */
  quoteCount = 0;

  constructor(data?: PartialMessage<CreateBulkOpportunitiesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "api.CreateBulkOpportunitiesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "opportunities", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "quote_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateBulkOpportunitiesResponse {
    return new CreateBulkOpportunitiesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateBulkOpportunitiesResponse {
    return new CreateBulkOpportunitiesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateBulkOpportunitiesResponse {
    return new CreateBulkOpportunitiesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateBulkOpportunitiesResponse | PlainMessage<CreateBulkOpportunitiesResponse> | undefined, b: CreateBulkOpportunitiesResponse | PlainMessage<CreateBulkOpportunitiesResponse> | undefined): boolean {
    return proto3.util.equals(CreateBulkOpportunitiesResponse, a, b);
  }
}

